import React from 'react'

const Pricing = () => {
  return (
    <>
    <div className="box"></div>
      <div className="container">
        <h2>Refund and Cancellation Policy</h2>
        <ol type="1">
          <li>
            The laws of India shall govern this agreement and are only subject
            to the exclusive jurisdiction of the courts of Aligarh, UP.
          </li>
          <li>
            Paai India [doctortime] makes no representation that services are
            appropriate for patients in any other location except India as Paai
            India [doctortime] only operates and controls this website from
            Aligarh, India.
          </li>
          <li>
            If any section of the agreement is determined to be invalid or
            unenforceable pursuant to applicable law then the said provision
            will be superseded by a valid, enforceable provision and the
            remainder of the agreement shall continue to be in effect.
          </li>
          <li>
            You acknowledge that the relationship created through your use of
            this website shall be of principal to principal. You shall not
            represent yourself as someone else, a representative agent or
            employee of Paii India [doctortime].
          </li>
          <li>
            You represent and warrant that you’re not a minor (i.e., you are
            above 18 years of age) and are competent and eligible to enter into
            a legally binding agreement.
          </li>
          <li>
            Paii India [doctortime] is NOT responsible for any doctor and
            patient relationship, it is mentioned that the Doctor Time app and
            website is a bridge between doctor and patient to make appointments
            and generate tokens further.
          </li>
          <li>
            . In any case if the patient is unable to confirm an appointment and
            the slip of Rs 10/- INR has been charged, the Paii Inida
            [doctortime] will refund the amount of Rs. 10 within 7 working days.
          </li>
          <li>
            Discrepancy in booking the appointment due to network problems and
            website crashes will also lead to a refund if the slip has been
            generated.
          </li>
          <li>
            If the payment has been made and the token no. has not been
            generated then the doctortime will refund the amount in 7 working
            days.
          </li>
        </ol>

        <h2>Pricing Plan</h2>
        <ol type="1">
          <li>
            Paai India [doctortime] offers real-time services to its patients.
            It acts as a bridge between the patients and the doctors.{" "}
          </li>
          <li>
            The charge for booking an appointment with a doctor is Rs 10 per
            token, which is valid for a day and according to the appointment
            booked with a doctor.
          </li>
          <li>
            Paai India does not charge extra for token generation. One token of
            10 Rs. For one doctor's appointment.
          </li>
          <li>
            The 10rs token generated by the patient only charge is applicable to
            all doctor's appointments.
          </li>
          <li>
            The registration of Rs 1000/ for the doctors is applicable and is
            valid only for one month.
          </li>
          <li>
            It is made sure that Paii India [doctortime] can change the plan
            accordingly in the future.
          </li>
        </ol>
      </div>
    </>
  );
}

export default Pricing